import './client-list.scss';
import Breadcrumbs from '../../shared/breadcrumb';
import GenericTable, { TableColumn } from '../../shared/tables/generic.table';
import React, { useEffect, useState } from 'react';
import TimeZoneSelectModal from './components/time-zone.modal';
import { DateTime } from 'luxon';
import DeleteClientModal from './components/delete-client.modal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import styles from '../../../index.module.scss';
import DisconnectClientModal from './components/disconnect-client.modal';
import useOAuth from '../../auth/redirectUrl/service';
import { Box, Button, Typography } from '@mui/material';
import { UserModel, userRefresh } from '../../shared/auth.functions';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCompanyList } from '../../shared/redux/companySlicer';
import { RootState } from '../../../store';
import Loader from '../../shared/Loader/loader';
import { setCurrentRealm } from '../../shared/redux/currentRealmSlicer';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../shared/toast.service';
import { MainRoute } from '../../../app';
import { UserRoute } from '../userContainer';
import { formatTimeOffset } from '../../shared/date-time.formater';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import NeedSubscribeModal from '../stripe/delete-client.modal';
import SetupClientModal from '../dashdoard/setup-client.modal';
import AddNewFirmModal from '../add-new-firm.modal';
import apiClient from '../../shared/auth.interceptor';
import { toast } from 'react-toastify';
import { setCurrentFirmList } from '../../shared/redux/firmListSlicer';

interface ConnectedUser {
  _id: boolean;
  type: string;
}

interface ReferenceType {
  value: string;
  name?: string;
}

interface ModificationMetaData {
  CreateTime: string;
  LastUpdatedTime: string;
}

export interface AccountQB {
  Id: string; // Unique identifier
  Name: string; // User-recognizable name
  SyncToken: string; // Version number of the object
  AcctNum?: string; // User-defined account number
  CurrentBalance?: number;
  CurrencyRef?: ReferenceType; // Currency reference
  ParentRef?: ReferenceType; // Parent account reference
  Description?: string; // User entered description
  Active?: boolean; // Whether or not active
  MetaData?: ModificationMetaData; // Metadata about the account
  SubAccount?: boolean; // Specifies whether a subaccount
  Classification?: string; // Classification of the account
  FullyQualifiedName?: string; // Fully qualified name
  AccountType: string; // Account type
  AccountSubType?: string; // Account sub-type
  CurrentBalanceWithSubAccounts?: number; // Balance with subaccounts

  domain?: string;
  sparse?: boolean;
}

export interface Company {
  timezone: string;
  realmId: string;
  connectionOn: string,
  expiredAt: string,
  companyName: string,
  connectedBy: string,
  firmName: string,
  connectedUsers: ConnectedUser[],
  connected?: boolean;
  disconnectedAt: string;
}

export type Client = {
  id: number;
  name: string | React.JSX.Element;
  email: string;
  connectedOn: string;
  timeZone: React.JSX.Element;
  status: React.JSX.Element;
  actions: React.JSX.Element; // React элементы для иконок действий
};

const columns: TableColumn[] = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Connected by', minWidth: 100 },
  { id: 'connectedOn', label: 'Connected since', minWidth: 100 },
  { id: 'timeZone', label: 'Time Zone', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'actions', label: 'Action', minWidth: 40 }
];
const ClientsList = () => {
  const queryParams = new URLSearchParams(location.search);
  const currentRealmId = queryParams.get('realmId');
  const currentFirm = useSelector((state: RootState) => state.currentFirm.currentFirm);
  const crumbs = [{ title: currentFirm || '' }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const [loading, setLoading] = useState<boolean>(true);
  const [clientLimited, setClientLimited] = useState<boolean>(true);
  const [openSetupClientModal, setSetupClientModal] = useState(false);
  const [openAddFirmModal, setOpenAddFirmModal] = useState(false);
  const [formatedClients, setFormatedClients] = useState<Client[]>([]);
  const { goToOauthPage } = useOAuth();
  const handleConnectQB = (realmId: string | null = null) => {
    if (!currentFirm) {
      setOpenAddFirmModal(true);
      return;
    }
    if (clientLimited && companies.length === 0) {
      navigate(MainRoute.User + UserRoute.Subscription);
      return;
    }
    if (clientLimited) {
      setOpenNeedSubscribeModal(true);
      return;
    }
    dispatch(setCurrentRealm(realmId));
    goToOauthPage().then(response => {
      if (response.isLimit) {
        setOpenNeedSubscribeModal(true);
      }
    });
  };
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = (client: Company) => {
    setSelectedClient(client);
    setOpenEditModal(true);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (client: Company) => {
    setSelectedClient(client);
    setOpenDeleteModal(true);
  };
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const handleOpenDisconnectModal = (client: Company) => {
    setSelectedClient(client);
    setOpenDisconnectModal(true);
  };

  const handleSetupClientModal = (client: Company) => {
    setSelectedClient(client);
    setSetupClientModal(true);
  };
  const handleSetupClientModalClose = () => {
    setSetupClientModal(false);
    const formData = {
      firmName: currentFirm
    };
    apiClient.put<UserModel>(`/v1/user/clients/setup/${currentRealmId}`, formData)
      .then((response) => {
        toast.success('Client setup completed');
        dispatch(setCurrentFirmList(response.data?.firmNames));
        dispatch(setCurrentCompanyList(response.data?.companies || []));
      }).catch(() => {
      errorToast('Failed to setup Client');
    });
  };
  const handleSuccess = (companies: Company[]) => {
    if (companies) {
      dispatch(setCurrentCompanyList(companies || []));
    }
  };
  const handleEditClick = (client: Company) => {
    if (!client.timezone) {
      setOpenEditModal(true);
      return;
    }
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${client.realmId}`);
  };
  const [openNeedSubscribeModal, setOpenNeedSubscribeModal] = useState(false);

  const goToSubscribe = () => {
    navigate(MainRoute.User + UserRoute.PricingTable);
  };
  const formatClients = (): (Client[]) => {
    if (!companies?.length) {
      return [];
    }
    return companies?.reduce((acc, client: Company, index) => {
      if (!currentFirm || !client.firmName || currentFirm !== client.firmName) {
        return acc;
      }
      const formattedClient = {
        id: index + 1,
        name: (
          <span className="clients-list-span "><a onClick={() => handleEditClick(client)}>{client.companyName || ''}</a></span>
        ),
        email: client.connectedBy || '',
        connectedOn: client.connectionOn
          ? DateTime.fromISO(client.connectionOn).toFormat('M/d/yyyy')
          : 'N/A',
        timeZone: (
          <div className="timeZone" onClick={() => handleOpenEditModal(client)}>
            <div>{formatTimeOffset(client.timezone || '')}</div>
            <EditOutlinedIcon sx={{ color: styles.mainGrey, marginLeft: '10px', paddingBottom: '2px' }}/>
          </div>),
        status: (
          <div>
            <div>{client.connected ? 'Connected' : 'Disconnected'}
            </div>
            <div>
              {(!client.connected && client.disconnectedAt) ?
                DateTime.fromISO(client.disconnectedAt).toFormat('M/d/yyyy') : ''}
            </div>
          </div>
        ),
        actions: (
          <div className="d-flex justify-content-between flex-nowrap">
            {client.connected ?
              <Button variant="outlined" onClick={() => handleOpenDisconnectModal(client)}
                      sx={{
                        whiteSpace: 'nowrap',
                        fontFamily: 'AvenirMediumFont, sans-serif', fontSize: '12px', fontWeight: 600,
                        height: '24px', borderWidth: '2px', borderRadius: '4px',
                        borderColor: 'rgba(107, 108, 114, 0.3)',
                        color: 'rgba(107, 108, 114, 0.3)',
                        letterSpacing: '0.1px', lineHeight: '16px',
                        width: '164px',
                        '&:hover': {
                          borderWidth: '2px', borderColor: styles.qbBorderGrey,
                          backgroundColor: styles.qbBorderGreyHover, color: styles.qbTextBlack
                        }
                      }}
              >Disconnect from QB</Button> :
              <Button variant="outlined" onClick={() => handleConnectQB(client.realmId)}
                      sx={{
                        whiteSpace: 'nowrap',
                        fontFamily: 'AvenirMediumFont, sans-serif', fontSize: '12px', fontWeight: 600,
                        height: '24px', borderWidth: '2px', borderRadius: '4px', borderColor: styles.qbBorderGrey,
                        color: styles.qbTextBlack,
                        letterSpacing: '0.1px', lineHeight: '16px',
                        '&:hover': {
                          borderWidth: '2px', borderColor: styles.qbBorderGrey,
                          backgroundColor: styles.qbBorderGreyHover
                        }
                      }}
              >Connect to QuickBooks</Button>
            }
            <div className="d-flex justify-content-end flex-nowrap ms-2">
              <a onClick={() => handleEditClick(client)}>
                <ReceiptLongOutlinedIcon className="me-1" color="info"/>
              </a>
              <a onClick={() => handleOpenDeleteModal(client)}>
                <DeleteOutlinedIcon color="error"/>
              </a>
            </div>
          </div>
        )
      };
      return [...acc, formattedClient];
    }, [] as Client[]);
  };
  useEffect(() => {
    userRefresh(dispatch).then(user => {
        setClientLimited(user.clientLimit - user.companies.filter(c => c.connected).length <= 0);
      })
      .catch(() => {
        errorToast('An error occurred while fetching clients');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (currentRealmId) {
      const company = companies?.find((company) =>
        company.realmId === currentRealmId && !company.firmName);
      if (company) {
        handleSetupClientModal(company);
      }
    }
  }, [currentRealmId, companies]);
  useEffect(() => {
    setFormatedClients(formatClients());
  }, [currentFirm, companies, clientLimited]);
  if (loading) {
    return <div><Loader/></div>;
  }
  return (
    <>
      <div className="mt-2">
        <Breadcrumbs crumbs={crumbs}/>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <Typography sx={{ fontSize: '2rem', fontWeight: 'bold' }}>Clients</Typography>
        <Button onClick={() => handleConnectQB()} className="primary"
                startIcon={<ControlPointOutlinedIcon/>}
                sx={{
                  whiteSpace: 'nowrap', fontSize: '18px',
                  height: '48px', gap: 0
                }}
        >Connect New Client</Button>
      </div>
      {formatedClients.length > 0 &&
       <Box
         sx={{ position: 'relative', height: 'calc(100vh - 200px)', width: 'calc(100vw - 380px)', overflow: 'auto' }}>
         <Box sx={{ position: 'relative', minWidth: '1000px' }}>
           <GenericTable columns={columns} data={formatedClients}/>
         </Box>
       </Box>}
      {formatedClients.length === 0 && <Typography className="mt-5">Clients list is empty.</Typography>}
      {selectedClient && (
        <TimeZoneSelectModal
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          onSuccess={handleSuccess}
          client={selectedClient} // Пропс для передачи в модальное окно
        />
      )}
      {selectedClient && (
        <DeleteClientModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          onSuccess={handleSuccess}
          client={selectedClient} // Пропс для передачи в модальное окно
        />
      )}
      {selectedClient && (
        <DisconnectClientModal
          open={openDisconnectModal}
          handleClose={() => setOpenDisconnectModal(false)}
          onSuccess={handleSuccess}
          client={selectedClient}
        />
      )}
      {(
        <NeedSubscribeModal
          open={openNeedSubscribeModal}
          handleClose={() => setOpenNeedSubscribeModal(false)}
          onSuccess={goToSubscribe}
        />
      )}
      {selectedClient && (
        <SetupClientModal
          open={openSetupClientModal}
          handleClose={handleSetupClientModalClose}
          handleSuccess={() => setSetupClientModal(false)}
          client={selectedClient}
        />
      )}

      {(<AddNewFirmModal
        open={openAddFirmModal}
        handleClose={() => setOpenAddFirmModal(false)}
        onSuccess={() => {
          setOpenAddFirmModal(false);
          goToOauthPage().then(response => {
            if (response.isLimit) {
              setOpenNeedSubscribeModal(true);
            }
          });
        }}
        edit={false}
      ></AddNewFirmModal>)}
    </>);
};
export default ClientsList;
