import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../shared/breadcrumb';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Company } from '../clients-list';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../client-list.scss';
import GenericTable, { TableColumn } from '../../../shared/tables/generic.table';
import { DateTime } from 'luxon';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import active from './active.svg';
import disabled from './disable.svg';
import apiClient from '../../../shared/auth.interceptor';
import { JournalTemplateResponse } from './base-template-form';
import { errorToast } from '../../../shared/toast.service';
import { MainRoute } from '../../../../app';
import { UserRoute } from '../../userContainer';
import DeleteTemplateModal from './delete-template.modal';
import { formatTimeOffset } from '../../../shared/date-time.formater';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import NeedSubscribeModal from '../../stripe/delete-client.modal';
import axios from 'axios';
import styles from '../../../../index.module.scss';

const journalTemplatesColumns: TableColumn[] = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 100 },
  { id: 'frequency', label: 'Frequency', minWidth: 100 },
  { id: 'lastPostingDate', label: 'Last Posting Date', minWidth: 100 },
  { id: 'nextPostingDate', label: 'Next Posting Date', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'actions', label: 'Action', minWidth: 100 }
];
const JournalTemplates = () => {
  const { realmId } = useParams(); // Получение realmId из URL
  const navigate = useNavigate();
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [templateLimited, setTemplateLimited] = useState<boolean>(true);
  const [journalTemplatesData, setJournalTemplatesData] = useState<JournalTemplateResponse[] | null>(null);
  const [openNeedSubscribeModal, setOpenNeedSubscribeModal] = useState(false);
  const crumbs = [
    { title: selectedClient?.firmName, href: `${MainRoute.User}${UserRoute.Clients}` },
    { title: selectedClient?.companyName },
    { title: 'Journal templates' }
  ];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<JournalTemplateResponse | null>(null);

  const formatDateWithTimezone = (date?: string, timezone?: string) => {
    if (!date || !timezone) {
      return 'N/A';
    }
    const dt = DateTime.fromISO(date, { zone: timezone });
    const formattedDate = dt.toFormat('LL/dd/yyyy HH:mm');

    return `${formattedDate} ${formatTimeOffset(timezone)}`;
  };
  const getLastPostingDate = (template?: JournalTemplateResponse, timezone?: string) => {
    return formatDateWithTimezone(template?.lastPostingDate, timezone);
  };
  const getNextPostingDate = (template?: JournalTemplateResponse, timezone?: string) => {
    return formatDateWithTimezone(template?.nextPostingDate, timezone);
  };
  const handleCreateClick = () => {
    if (templateLimited) {
      setOpenNeedSubscribeModal(true);
      return;
    }
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.Create}`);
  };
  const goToSubscribe = () => {
    navigate(MainRoute.User + UserRoute.PricingTable);
  };
  const handleEditClick = (templateId?: string) => {
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.Edit}/${templateId}`);
  };
  const handleViewClick = (templateId?: string) => {
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.View}/${templateId}`);
  };
  const handleDeleteClick = (template: JournalTemplateResponse) => {
    setSelectedTemplate(template);
    setOpenDeleteModal(true);
  };
  const handleDeleteSuccess = () => {
    setOpenDeleteModal(false);
    getTemplates()
      .then(answer => {
        setJournalTemplatesData(answer.data);
      }).catch(_ => {
      errorToast(`Failed getting Journal Templates`);
    });
  };
  const handleChangeStatus = async (template: JournalTemplateResponse) => {
    if (!template.active && templateLimited) {
      setOpenNeedSubscribeModal(true);
      return;
    }
    const updatedTemplate = { ...template, active: !template.active };
    try {
      const response = await apiClient.put(`/v1/journal/template/${template._id}/change-status`, updatedTemplate);
      if (response.status === 200 || response.status === 201) {
        getTemplates()
          .then(answer => {
            setJournalTemplatesData(answer.data);
          }).catch(_ => {
          errorToast(`Failed getting Journal Templates`);
        });
      } else {
        errorToast('Failed to change status');
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.message) {
          errorToast(error.response.data.message);
        }
      } else {
        errorToast('Failed to change status');
      }
    }
  };
  const formatJournalTemplatesData = () => {
    return journalTemplatesData?.map(template => ({
      name: template.name,
      description: template.description,
      frequency: template.frequency,
      lastPostingDate: getLastPostingDate(template, selectedClient?.timezone),
      nextPostingDate: template.active ? getNextPostingDate(template, selectedClient?.timezone) : '',
      status: template.active
        ? (<a onClick={() => handleChangeStatus(template)}>
          <img alt="Active" src={active} className="me-2"></img>Active
        </a>)
        : (<a onClick={() => handleChangeStatus(template)}>
          <img alt="Disabled" src={disabled} className="me-2"></img>Disabled
        </a>),
      actions: (
        <div className="d-flex justify-content-between flex-nowrap">
          <Tooltip title={!selectedClient?.connected ? 'Connect the current company to edit' : ''}
                   disableHoverListener={selectedClient?.connected} arrow
                   componentsProps={{
                     tooltip: {
                       sx: {
                         bgcolor: styles.mainGrey,
                         color: styles.mainWhite,
                         maxWidth: 'none',
                         whiteSpace: 'pre-wrap',
                         fontSize: '10px'
                       }
                     },
                     arrow: {
                       sx: {
                         color: styles.mainGrey
                       }
                     }
                   }}
          >
            <div>
              <IconButton
                disabled={!selectedClient?.connected}
                onClick={() => handleEditClick(template._id)}>
                <ModeEditOutlinedIcon/>
              </IconButton>
            </div>
          </Tooltip>
          <IconButton className="" onClick={() => handleViewClick(template._id)}>
            <VisibilityOutlinedIcon/></IconButton>
          <IconButton onClick={() => handleDeleteClick(template)}
          ><DeleteOutlinedIcon color="error"/></IconButton>
        </div>
      )
    }));
  };
  const getTemplates = async () => {
    return await apiClient.get<JournalTemplateResponse[]>(`/v1/journal/${realmId}`);
  };
  const updateTemplateLimit = () => {
    const templatePerClient = currentUser?.templatePerClient || 0;
    const currentCount = journalTemplatesData?.filter(e => e.active).length || 0;
    setTemplateLimited(templatePerClient - currentCount <= 0);
  };
  useEffect(() => {
    const currentCompany = companies?.find((company) => company.realmId === realmId);
    setSelectedClient(currentCompany || null);
  }, [realmId]);
  useEffect(() => {
    getTemplates()
      .then(answer => {
        setJournalTemplatesData(answer.data);
      }).catch(() => {
      errorToast(`Failed getting Journal Templates`);
    });
  }, []);
  useEffect(() => {
    if (journalTemplatesData) {
      updateTemplateLimit();
    }
  }, [journalTemplatesData]);
  return (
    <>
      <div className="mt-2">
        <Breadcrumbs crumbs={crumbs}/>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex align-items-center">
          <ArrowBackIcon onClick={() => navigate('/user/clients')} sx={{ fontSize: '2rem', cursor: 'pointer' }}/>
          <Typography sx={{ fontSize: '2rem', marginLeft: '1rem', fontWeight: 'bold' }}>Journal
            templates</Typography>
        </div>
        <Tooltip title={!selectedClient?.connected ? 'Connect the current company' : ''}
                 disableHoverListener={selectedClient?.connected} arrow placement="top"
                 componentsProps={{
                   tooltip: {
                     sx: {
                       bgcolor: styles.mainGrey,
                       color: styles.mainWhite,
                       maxWidth: 'none',
                       whiteSpace: 'pre-wrap',
                       fontSize: '10px'
                     }
                   },
                   arrow: {
                     sx: {
                       color: styles.mainGrey
                     }
                   }
                 }}
        >
          <div>
            <Button onClick={handleCreateClick} className="primary"
                    disabled={!selectedClient?.connected}
                    startIcon={<ControlPointOutlinedIcon/>}
                    sx={{
                      whiteSpace: 'nowrap', fontSize: '18px',
                      height: '48px', gap: 0
                    }}>
              Create Template
            </Button>
          </div>
        </Tooltip>
      </div>
      {journalTemplatesData && journalTemplatesData?.length > 0 &&
       <Box
         sx={{ position: 'relative', height: 'calc(100vh - 200px)', width: 'calc(100vw - 380px)', overflow: 'auto' }}>
         <Box sx={{ position: 'relative', minWidth: '1000px' }}>
           <GenericTable columns={journalTemplatesColumns} data={formatJournalTemplatesData()}/>
         </Box>
       </Box>}
      {journalTemplatesData?.length === 0 && <Typography className="mt-3">Journal templates list is empty.</Typography>}
      {selectedTemplate && (
        <DeleteTemplateModal
          open={openDeleteModal}
          handleClose={handleDeleteSuccess}
          template={selectedTemplate}
        />
      )}
      {(
        <NeedSubscribeModal
          open={openNeedSubscribeModal}
          handleClose={() => setOpenNeedSubscribeModal(false)}
          onSuccess={goToSubscribe}
        />
      )}
    </>
  );
};

export default JournalTemplates;
